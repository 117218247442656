<template>
  <div>
    <heade></heade>
    <div
      class="com"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="com_box">
        <div>
          <div class="com-left">
            <div class="com-left-top">{{ practice.name }}</div>
            <div class="com-left-com">
              <div class="com-left-com-item">·引言·</div>
            </div>
            <div class="com-left-foot">
              <div
                v-for="(item, index) in practice.division_category"
                :key="index"
                class="nice-scroll"
              >
                <div
                  class="navgatorLi"
                  v-for="(items, indexs) in item.division"
                  :key="indexs"
                >
                  <router-link
                    :to="{
                      path: detailPath,
                      query: { id: items.division_content_id },
                    }"
                    class="com-left-foot-but"
                    >{{ items.name }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="com-right">
            <el-button
              type="text"
              icon="el-icon-arrow-left"
              @click="$router.back(-1)"
              >返回上一页</el-button
            >
            <div class="nice-scroll" v-html="practice.content"></div>
          </div>
        </div>
        <div class="com-btt">
          <div>
            <div>
              <router-link
                v-for="(item, index) in practiceTheme"
                :key="index"
                :to="{
                  path: path,
                  query: { id: item.id },
                }"
                :class="
                  item.id == practice.id
                    ? 'com-bbt-item active'
                    : 'com-bbt-item'
                "
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { scroll } from "@utils/scroll";
import { getpracticeCourse, practiceCoursesByModularId } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
    practice() {
      this.$nextTick(function () {
        scroll($(".nice-scroll"));
      });
    },
  },

  props: {},
  data() {
    return {
      practice: [],
      queryWhere: {},
      modularQueryWhere: {},
      practiceTheme: [],
      content: "",
      backgroud: "",
      detailPath: "",
      path: "",
    };
  },
  mounted: function () {
    let that = this;
    getpracticeCourse(that.queryWhere).then((res) => {
      that.$set(that, "practice", res.data);
    });

    practiceCoursesByModularId(that.modularQueryWhere).then((res) => {
      that.$set(that, "practiceTheme", res.data);
    });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    this.modularQueryWhere.modular_id = this.$route.meta.id;
    this.detailPath = this.$route.meta.detailPath;
    this.path = this.$route.meta.path;
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },

  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>
<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.el-button--text {
  color: rgb(121, 0, 0);
}
.com_box {
  width: 1484px;
  height: 1660px;
  margin: 0 auto;
  position: relative;
}

.com-btt {
  width: 911px;
  font-size: 14px;
}
.com-btt > div > div {
  width: 640px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 193px;
}

/* 隐藏滚动条 */
.com-left-foot::-webkit-scrollbar {
  display: none;
}
.com-bbt-item {
  width: 15px;
  font-size: 18px;
  line-height: 22px;
}
.active {
  color: #900c0c;
}
</style>
